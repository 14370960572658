import { ActivityIndicator } from 'components/Shared/ActivityIndicator'
import { ErrorIndicator } from 'components/Shared/ErrorIndicator'
import { useCurrentCorporateAccountStore } from 'stores/useCurrentCorporateAccountStore'
import { useGetMe } from 'hooks/api/useGetMe'
import NoPatronsCheckedInIcon from 'assets/NoPatronsCheckedIn.png'
import { NoDataIndicator } from 'components/Shared/NoDataIndicator'
import { RewardRedemptionsTable } from './RewardRedemptionsTable'
import { useGetRewardRedemptionsByLeId } from 'hooks/api/Reports/useGetRewardRedemptionsByLeId'
import { useParams } from 'react-router-dom'
import { ReportsHeader } from '../ReportsHeader'
import { useState } from 'react'
import { Stack } from '@mui/material'

import { DateRangeSelectHeader } from 'components/DateRangeSelectHeader'

export const RewardRedemptionsPage = () => {
  const { id } = useParams()
  const meQuery = useGetMe()
  const selectedCorporateAccountId =
    useCurrentCorporateAccountStore().currentCorporateAccountId
  const licensedEstablishments = meQuery.data?.licensedEstablishments?.filter(
    (le) => le.corporateAccountId === selectedCorporateAccountId
  )
  const piiPermission = meQuery.data?.user?.reportsAccessToPIIPermission
  const downloadPIIPermission = meQuery.data?.user?.reportsDownloadPIIPermission

  const getDateOffset = (days: number) => {
    const date = new Date()
    date.setDate(date.getDate() + days)
    return date
  }

  const [numDays, setNumDays] = useState<string>('30')
  const [startDate, setStartDate] = useState<Date>(getDateOffset(-30))
  const [endDate] = useState<Date>(getDateOffset(1))

  const reportQuery = useGetRewardRedemptionsByLeId({
    currentPage: 1,
    pageSize: 100,
    licensedEstablishmentId: Number(id),
    startDate,
    endDate,
  })

  return (
    <ReportsHeader currentTab={`/Reports/${id}/PatronRewardRedemptions`}>
      {!licensedEstablishments || licensedEstablishments.length === 0 ? (
        <NoDataIndicator
          imageSource={NoPatronsCheckedInIcon}
          noDataMessage="Cannot view reports because there are no locations available for this corporate account"
        />
      ) : (
        <Stack spacing={4}>
          {reportQuery.isPending && reportQuery.isFetching && (
            <ActivityIndicator />
          )}
          {reportQuery.isError && <ErrorIndicator />}
          {reportQuery.data && (
            <DateRangeSelectHeader
              label={'Redemptions'}
              setNumDays={setNumDays}
              numDays={numDays}
              setStartDate={setStartDate}
              dateOffset={getDateOffset}
            >
              <RewardRedemptionsTable
                data={reportQuery.data.results ?? []}
                piiPermission={piiPermission ?? false}
                downloadPIIPermission={downloadPIIPermission ?? false}
              />
            </DateRangeSelectHeader>
          )}
        </Stack>
      )}
    </ReportsHeader>
  )
}
